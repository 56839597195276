/* @import url('https://fonts.googleapis.com/css2?family=Noto+Music&display=swap'); */

@font-face {
    font-family: 'Noto Music';
    src: url('/assets/fonts/noto-music-v20.woff2') format('woff2');
    font-display: block; /* Ensures no fallback font is used */
}

:root{
    /* Global color schemes */
    --primary-color: #CBF3E6;
    --primary-accent: #b0d4c9;
    --secondary-color: #1dffbb;
    --secondary-accent: #17d49c;
    --tertiary-color: #88cafa;
    --tertiary-accent: #48a7eb;
    --accent-color-1: #3479aa; 
    --accent-color-2: #0B0B30;
    --background-color: #ebe9ec;
    --background-color-2: #333;
    --background-color-3: #414141;
    --button-background-color: #E2DEE4;
    --button-hover-color: #d6d0da;
    --text-hover-color: #444444;
    --green: #0ea853;
    --red: #c41111;

    /* Global fonts */
    --primary-font: 'Noto Music', sans-serif !important;

    /* Global border */
    --border: 1px solid black;

    /* Global shadow */
    --shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

    /* Background cascade colors */
    --background-cascade-primary: rgba(176, 212, 201, 0.4);
    --background-cascade-secondary: rgba(23, 212, 156, 0.2);
    --background-cascade-tertiary: rgba(72, 167, 235, 0.2);

}

/* Normalize styles for platform independence */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */
.staff-building-control-container button {
    border: var(--border);
    
    font-family: 'Noto Music', sans-serif;
    font-size: inherit;
    color: inherit;
    cursor: pointer;
    width: 100%; 

}

.staff-building-control-container button:hover{
    background-color: var(--button-hover-color);
}


/* .staff-building-control-container button {
    font-family: 'Noto Music', sans-serif;
    font-size: 48px; 
    display: flex; 
    justify-content: center;
    align-items: center; 
    width: 100%; 
    max-height: 60px;
    padding: 0.5em;
    padding-top: 0;
    padding-bottom: 0.7em;
    box-sizing: border-box; 
    text-align: center; 
    line-height: 1;
    overflow: hidden; 

} */


main{

    display: flex;
    flex-direction: column;
    /* height: 100%; */
    /* font-family: var(--primary-font); */
    min-height: 100vh; 
    background: linear-gradient(to bottom, var(--background-cascade-primary), var(--background-color));
    /* background-color: var(--background-color); */
}

canvas {
    font-family: 'Noto Music', sans-serif;
}


.app-parent-container{
    /* margin-left: 35%;
    margin-right: 35%; */
    margin: 0 auto;
    min-height: 100vh; 
    max-width: 600px;
    background-color: white;
    box-shadow: var(--shadow); 

}

.main-container{
    flex: 1;
    margin-top: 8%;
    padding-bottom: 8em;
    display: grid;
    grid-template-rows: auto;
    height: 100%;
    background-color: #ffffff;
}

/* @media (max-width: 4000px){
    .app-parent-container{

    }

}
@media(max-width: 1440px){
    .app-parent-container{
        margin-left: 30%;
        margin-right: 30%;
    }

} */

/* @media(max-width: 1060px){
    .app-parent-container{
        margin-left: 25%;
        margin-right: 25%;
    }
}

@media(max-width: 850px){
    .app-parent-container{
        margin-left: 15%;
        margin-right: 15%;
    }

} */

@media(max-width: 605px){
    .app-parent-container{
        padding-top: 1.5em;
    }

}




/* Global button styling */
.button {
    background-color: var(--button-background-color);
    /* width: 130px; */
    border: none;
    border-radius: 5px;
    color: black;
    padding: 8px;
    font-size: 20px;
    text-align: center;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
}

.button.selected {
    background-color: var(--primary-color);
    color: #f0f0f0;
}


.button:hover{
    background-color: #c2bdbd;
}

.button.selected:hover{
    background-color: var(--primary-accent);
}

.invisible{
    visibility: hidden;
}

/* Global submit button styling */

.submit-button-container{
    width: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center;
}

.submit-button{
    background-color: #c9c4cc;
    border: none;
    font-weight: 500;
    font-size: 18px;
    width: 100%;
}

.submit-left{
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.submit-right{
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}


/* Color flash cascading animation */
@keyframes greenCascade {
    0% { background-color: var(--green); }
    100% { background-color: initial; }
}

@keyframes greenCascade-primary {
    0% { background-color: var(--green); }
    100% { background-color: var(--primary-color); }
}

@keyframes greenCascade-secondary {
    0% { background-color: var(--green); }
    100% { background-color: var(--secondary-color); }
}

@keyframes greenCascade-tertiary {
    0% { background-color: var(--green); }
    100% { background-color: var(--tertiary-color); }
}

@keyframes redCascade {
    0% { background-color: var(--red); }
    100% { background-color: var(--primary-color); }
}

@keyframes redCascade-primary {
    0% { background-color: var(--red); }
    100% { background-color: var(--primary-color); }
}

@keyframes redCascade-secondary {
    0% { background-color: var(--red); }
    100% { background-color: var(--secondary-color); }
}

@keyframes redCascade-tertiary {
    0% { background-color: var(--red); }
    100% { background-color: var(--tertiary-color); }
}

.flash-green{
    animation: greenCascade 0.5s ease forwards;
}

.flash-green-primary{
    animation: greenCascade-primary 0.5s ease forwards;

}

.flash-green-secondary{
    animation: greenCascade-secondary 0.5s ease forwards;

}

.flash-green-tertiary{
    animation: greenCascade-tertiary 0.5s ease forwards;

}

.flash-red{
    animation: redCascade 0.5s ease forwards;
}

.flash-red-primary{
    animation: redCascade-primary 0.5s ease forwards;

}

.flash-red-secondary{
    animation: redCascade-secondary 0.5s ease forwards;

}

.flash-red-tertiary{
    animation: redCascade-tertiary 0.5s ease forwards;

}

::ng-deep .score-sheet-container{
    padding: 0px !important;
    margin: none !important;
    border: none !important;
    /* font-family: var(--primary-font); */

}

/* Global container styling */
.container{
    display: grid;
    grid-template-rows: auto;
    align-items: center;
}

.logo-container{
    /* width: 500px; */
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
}

.logo-container h3{
    padding-top: 1em;
}

.logo-container-white{
    background-color: #ffffff;
}

.logo-container-grey{
    background-color: var(--background-color);
}

.logo-container h3{
    margin-top: 0 !important;
    margin-bottom: 0.2em;
}

.logo-container img{
    max-width: 100%;
    height: 135px;
}

.modal-content {
    position: absolute;
    top: 55px;
    left: -50px; 
    width: 100px; 
    background-color: var(--background-color-2);
    border: 1px solid #ccc;
    z-index: 1000;
    text-align: left;
}

  
.modal-content p{
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
} 

.modal-selection{
    padding: 10px;
    &:hover {
        background-color: var(--background-color-3);
        cursor: pointer;
    }
}

/* Global svg styling */
svg{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;  
    margin: auto;
}

svg:hover{
    fill: #4caf50;
    stroke: #4caf50;
}

svg.circular-loader {
    width: 25px; 
    height: 25px;
}


.auth-control-container button {
    border: 1px solid black;
    border-radius: 5px;
    font-weight: bold;
    padding: 0.5em;
    margin-top: 0.5em; 
}

.auth-button-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
}

.auth-button-container button{
    width: 100% !important;
    border-radius: 0 !important;
}

.auth-control-container button:hover{
    background-color: #d6d0da;
}

.auth-field-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 1em;

}


.auth-field-container label{
    text-align: left;
    font-weight: bold;
    padding-bottom: 0.2em;
    /* width: 90%;  */
}

.auth-field-container p{
    text-align: left !important;
}

.auth-input, .auth-input-error{
    align-items: center;
    box-sizing: border-box; 
}

.auth-input{
    border: 1px solid black;
    border-radius: 5px;
    height: 30px;
}

/* Global error messages */
.auth-input-error{
    border: 1px solid var(--red);
    border-radius: 5px;
    height: 30px;
}

.auth-info-message, .auth-error-message{
    font-size: 0.9em;
    font-weight: bold;
    text-align: center;

}
.auth-error-message{
    color: var(--red);
    font-weight: bold;
    text-align: center !important;

}

/* Global success messages */
.auth-success-message{
    color: var(--green);
    text-align: center !important;
}

.auth-error-message-left{
    text-align: left !important;
    /* margin-left: 5%;
    margin-right: 5%; */
}

.auth-info-message{
    color: #000000;
}

select{
    width: 225px;
}



/* Container styling for list-based elements */
.record-container{
    background-color: var(--background-color-2);
    color: #ffffff;
    display: grid;
    grid-template-columns: repeat(7, 110px);
    font-size: 14px;
    margin-left: 25%;
    margin-right: 25%;
    white-space: normal; 
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    overflow: hidden; 

}

.record-container-admin{
    background-color: var(--background-color-2); 
    color: #ffffff;
    display: grid;
    grid-template-columns: repeat(4, 250px);

}

.record-container-profile{
    grid-template-columns: repeat(7, 150px);
    font-size: 14px;

} 

.record-container-body{
    display: grid;
    grid-template-columns: repeat(7, 120px);
    font-size: 14px;
    margin-left: 25%;
    margin-right: 25%;
  

}

.record-container-body-admin{
    display: grid;
    grid-template-columns: repeat(4, 250px);
}

.record-container-body-admin:hover{
    background-color: #9b969e;
    cursor:pointer;
}

.record-container-profile{
    display: grid;
    grid-template-columns: repeat(7, 150px);
    font-size: 14px;
}


.record-attribute{
    border: 1px solid #919090;
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
}

.black-font{
    color: black;

}

/* Dialog box styling */
.dialog-header{
    color: #000000;
    text-align: center;
    font-weight: bold;
}

.dialog-header-left{
    text-align: left !important;
}

/* Alternating row colors for table views */
.even-row{
    background-color: #ffffff;
}

.odd-row{
    background-color: #c9c4cc;
}

/* Basic margin classes */
/* .margin-top{
    margin-top: 2%;
} */

.margin-top-icon{
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}

.margin-bottom{
    margin-bottom: 2%;
}

/* Specific background colors for different game modules */
.ear-training-container{
    background-color: var(--primary-color);
}

.ear-training-container-alt{
    background-color: var(--primary-accent);
}


.ear-training-container button:hover{
    background-color: var(--primary-accent);
}

.staff-identification-container{
    background-color: var(--secondary-color);
}

.staff-identification-container-alt{
    background-color: var(--secondary-accent);
}

.staff-identification-container button:hover{
    background-color: var(--secondary-accent);
}

.staff-building-container{
    background-color: var(--tertiary-color);
}

.staff-building-container-alt{
    background-color: var(--tertiary-accent);
}

.staff-building-container button:hover{
    background-color: var(--tertiary-accent);
}

.default-container{
    background-color: #c9c4cc;
}

.default-container-alt{
    background-color: #ffffff;
}

.bottom-border{
    border-bottom: var(--border);
}


app-nav-bar {
    flex: 0 0 auto; 
}
  
  /* Footer stays at the bottom */
  app-footer {
    flex: 0 0 auto; /* Fixed size (intrinsic or based on content) */
}


html, body { 
    height: 100%; 
    display: flex; 
    flex-direction: column;
    overflow: auto;
}
body { 
    margin: 0; 
    background-color: var(--background-color);
    font-family: var(--primary-font);
    background-color: var(--background-color);
}
h2 {
    margin-top: 0;    
    margin-bottom: 0; 
}

.mat-drawer-content{
    background-color: var(--background-color);
}

.background-color-primary{
    background: linear-gradient(to bottom, var(--background-cascade-primary), var(--background-color)) !important;
}

.background-color-secondary{
    background: linear-gradient(to bottom, var(--background-cascade-secondary), var(--background-color)) !important;
}

.background-color-secondary:hover{
    background-color: black;
}

.background-color-tertiary{
    background: linear-gradient(to bottom, var(--background-cascade-tertiary), var(--background-color)) !important;
}

/* Global styling for GIFs */
.gif-image-container{
    text-align:  center;
}

.gif-image-container img{
    width: 600px;
    height: auto;
    image-rendering: smooth;
}

@media(max-width: 600px) {
    .gif-image-container img{
        width: 300px;
        height: auto;
        image-rendering: smooth;
    }
}
